.home-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 70px;
  background-image: url('../images/bg.jpg'); 
  background-size: cover!important;
  color: #fff;
  min-height: 73vh!important;
  /* width: 100%; */
}
.spinner-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
  width: 100%; 
}
.svgClass{
  /* display: grid; */
  justify-content: center;
  margin-top:-60px;
  /* width: 100%!important; */
  /* -webkit-transform: scale(0.5); */
/* transform: scale(0.5); */
}
.list-items  {
  margin-top:10px;
}
.hero-section{
  margin-top:0px;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #ef980d;
}

.hero-section p {
  margin-top: 100px;
  font-weight: bolder;
  font-size: 1.2rem;
  margin-bottom: 20px;
  /* color: #da950e; */
  color:#e8ba64
}

.hero-section ul {
  color: #e8ba64;
  font-weight: bolder;
  margin-top: 115px;
  font-size: 1.3rem;
  list-style: none;
}

.hero-section ul li {
  margin-bottom: 10px;
  padding-left: 1rem;
  text-align: left;
}

.contact-form-section {
  margin-top: 50px;
  background-color: rgba(255, 255, 255, 0.9); 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 300px; 
}

.contact-form-section h2 {
  color: #333;
  margin-bottom: 15px;
}

.contact-form-section form {
  display: flex;
  flex-direction: column;
}

.contact-form-section input,
.contact-form-section textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form-section button {
  background-color: #800020; /* Adjust to match your brand color */
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .svgClass{
    display: grid;
    justify-content: center;
    width: 100%!important;
    -webkit-transform: scale(0.5);
 transform: scale(0.5);
  }
  svg-#logo{
    -webkit-transform: scale(0.5);
 transform: scale(0.5);
  }
  svg-#logo1{
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  
  .home-container {
      flex-direction: column;
      align-items: center;
 
    display: flex;
    justify-content: space-between;
    
    /* padding: 50px; */
    background-image: url('../images/bg.jpg'); 
    background-size: cover;
    color: #fff;
    height: 130vh;
    /* width: 74vw; */
    width: auto!important;
    background-size: cover;

  }
  

  .hero-section, .contact-form-section {
      width: 100%;
      text-align: center;
  }

  .hero-section ul {
      padding: 0;
  }
  .hero-section p {
    margin-top: 98px;
    font-weight: bolder;
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #e8ba64;
  }
  
  .hero-section ul {
    color: #e8ba64;
    font-weight: bolder;
    margin-top: 95px;
    font-size: 0.9rem;
    list-style: none;
  }
  
  
}


#logoz{
  /* position:absolute; */
  /* top:40%; */
  /* left:50%; */
  /* transform: translate(-50%, -50%); */
  /* animation: line-anim 2s ease forwards 45.5s; */
}

#logo path:nth-child(1){
  stroke-dasharray: 301.72802734375px;
  stroke-dashoffset: 301.72802734375px;
  animation: line-anim 2s ease forwards 0.3s;
  }
  #logo path:nth-child(2){
	stroke-dasharray: 279.9551696777344px;
  stroke-dashoffset: 279.9551696777344px;
  animation: line-anim 2s ease forwards 0.3s;
  }
  #logo path:nth-child(3){
   
	stroke-dasharray: 324.1768798828125px;
  stroke-dashoffset:  324.1768798828125px;
  animation: line-anim 2s ease forwards 0.6s;
  }
  #logo path:nth-child(4){
	stroke-dasharray: 475.135986328125px;
  stroke-dashoffset: 475.135986328125px;
  animation: line-anim 2s ease forwards 0.9s;
  }
  #logo path:nth-child(5){
	stroke-dasharray: 458.0119934082031px;
  stroke-dashoffset: 458.0119934082031px;
  animation: line-anim 2s ease forwards 1.2s;
  }
  #logo path:nth-child(6){
	stroke-dasharray: 411.55621337890625px;
  stroke-dashoffset: 411.55621337890625px;
  animation: line-anim 2s ease forwards 1.2s;
  }
  #logo path:nth-child(7){
	stroke-dasharray: 475.55621337890625px;
  stroke-dashoffset: 475.55621337890625px;
  animation: line-anim 2s ease forwards 1.8s;
  }
  #logo path:nth-child(8){
	stroke-dasharray: 381.7067565917969px;
  stroke-dashoffset: 381.7067565917969px;
  animation: line-anim 2s ease forwards 1.4s;
  }
  #logo path:nth-child(9){
	stroke-dasharray: 381.7067565917969px;
	stroke-dashoffset: 381.7067565917969px;
  animation: line-anim 2s ease forwards 1.6s;
  }

  #logo path:nth-child(10){
	stroke-dasharray: 381.7067565917969px;
  stroke-dashoffset: 381.7067565917969px;
  animation: line-anim 2s ease forwards 1.8s;
  }

  #logo path:nth-child(11){
	stroke-dasharray: 390.755859375px;
  stroke-dashoffset: 390.755859375px;
  animation: line-anim 2s ease forwards 1.8s;
  }
#logo path:nth-child(12){
  stroke-dasharray: 390.755859375px;
stroke-dashoffset: 390.755859375px;
animation: line-anim 2s ease forwards 2s;
}

#logo path:nth-child(13){
  stroke-dasharray: 450.755859375px;
stroke-dashoffset: 450.755859375px;
animation: line-anim 2s ease forwards 2s;
}

#logo path:nth-child(14){
  stroke-dasharray: 490.755859375px;
stroke-dashoffset: 490.755859375px;
animation: line-anim 2s ease forwards 2s;
}

#logo path:nth-child(15){
  stroke-dasharray: 540.755859375px;
stroke-dashoffset: 540.755859375px;
animation: line-anim 2s ease forwards 2s;
}

#logo path:nth-child(16){
  stroke-dasharray: 590.755859375px;
stroke-dashoffset: 590.755859375px;
animation: line-anim 2s ease forwards 2s;
}

#logo path:nth-child(17){
  stroke-dasharray: 640.755859375px;
stroke-dashoffset: 640.755859375px;
animation: line-anim 2s ease forwards 2s;
}

#logo path:nth-child(18){
  stroke-dasharray: 700.755859375px;
stroke-dashoffset: 700.755859375px;
animation: line-anim 2s ease forwards 2s;
}
/* logo 1 starts here */
#logo1 path:nth-child(1){
  stroke-dasharray: 301.72802734375px;
  stroke-dashoffset: 301.72802734375px;
  animation: line-anim 2s ease forwards 0.3s;
  }
  #logo1 path:nth-child(2){
	stroke-dasharray: 279.9551696777344px;
  stroke-dashoffset: 279.9551696777344px;
  animation: line-anim 2s ease forwards 0.3s;
  }
  #logo1 path:nth-child(3){
   
	stroke-dasharray: 324.1768798828125px;
  stroke-dashoffset:  324.1768798828125px;
  animation: line-anim 2s ease forwards 0.6s;
  }
  #logo1 path:nth-child(4){
	stroke-dasharray: 475.135986328125px;
  stroke-dashoffset: 475.135986328125px;
  animation: line-anim 2s ease forwards 0.9s;
  }
  #logo1 path:nth-child(5){
	stroke-dasharray: 458.0119934082031px;
  stroke-dashoffset: 458.0119934082031px;
  animation: line-anim 2s ease forwards 1.2s;
  }
  #logo1 path:nth-child(6){
	stroke-dasharray: 411.55621337890625px;
  stroke-dashoffset: 411.55621337890625px;
  animation: line-anim 2s ease forwards 1.2s;
  }
  #logo1 path:nth-child(7){
	stroke-dasharray: 475.55621337890625px;
  stroke-dashoffset: 475.55621337890625px;
  animation: line-anim 2s ease forwards 1.8s;
  }
  #logo1 path:nth-child(8){
	stroke-dasharray: 381.7067565917969px;
  stroke-dashoffset: 381.7067565917969px;
  animation: line-anim 2s ease forwards 1.4s;
  }
  #logo1 path:nth-child(9){
	stroke-dasharray: 381.7067565917969px;
	stroke-dashoffset: 381.7067565917969px;
  animation: line-anim 2s ease forwards 1.6s;
  }

  #logo1 path:nth-child(10){
	stroke-dasharray: 381.7067565917969px;
  stroke-dashoffset: 381.7067565917969px;
  animation: line-anim 2s ease forwards 1.8s;
  }

  #logo1 path:nth-child(11){
	stroke-dasharray: 390.755859375px;
  stroke-dashoffset: 390.755859375px;
  animation: line-anim 2s ease forwards 1.8s;
  }
#logo1 path:nth-child(12){
  stroke-dasharray: 390.755859375px;
stroke-dashoffset: 390.755859375px;
animation: line-anim 2s ease forwards 1.8s;
}

#logo1 path:nth-child(13){
  stroke-dasharray: 450.755859375px;
stroke-dashoffset: 450.755859375px;
animation: line-anim 2s ease forwards 1.9s;
}

#logo1 path:nth-child(14){
  stroke-dasharray: 490.755859375px;
stroke-dashoffset: 490.755859375px;
animation: line-anim 2s ease forwards 1.9s;
}

#logo1 path:nth-child(15){
  stroke-dasharray: 540.755859375px;
stroke-dashoffset: 540.755859375px;
animation: line-anim 2s ease forwards 2s;
}

#logo1 path:nth-child(16){
  stroke-dasharray: 590.755859375px;
stroke-dashoffset: 590.755859375px;
animation: line-anim 2s ease forwards 2s;
}

#logo1 path:nth-child(17){
  stroke-dasharray: 640.755859375px;
stroke-dashoffset: 640.755859375px;
animation: line-anim 2s ease forwards 2s;
}

#logo1 path:nth-child(18){
  stroke-dasharray: 700.755859375px;
stroke-dashoffset: 700.755859375px;
animation: line-anim 2s ease forwards 2.2s;
}

#logo1 path:nth-child(19){
  stroke-dasharray: 750.755859375px;
stroke-dashoffset: 750.755859375px;
animation: line-anim 2s ease forwards 2.2s;
}
#logo1 path:nth-child(20){
  stroke-dasharray: 790.755859375px;
stroke-dashoffset: 790.755859375px;
animation: line-anim 2s ease forwards 2.2s;
}
#logo1 path:nth-child(21){
  stroke-dasharray: 800.755859375px;
stroke-dashoffset: 800.755859375px;
animation: line-anim 2s ease forwards 2.2s;
}



@keyframes line-anim {
	to{
	  stroke-dashoffset: 0;
	}
  }
  @keyframes fill {
	from{
	  fill:transparent;
	}
	to{
	  fill:transparent;
	}
  }