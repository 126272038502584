.schedule-form-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff; 
}

.schedule-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-row {
    display: flex;
    justify-content: space-between;
}

.form-group {
    flex: 1;
    margin-right: 20px;
}

.form-group:last-child {
    margin-right: 0;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="tel"],
.form-group input[type="email"],
.form-group select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px; 
    border: 1px solid #ccc; 
    border-radius: 5px;
}

button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #f1c40f;
    color: #002244; 
    border-radius: 5px;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
    }

    .form-group {
        margin-right: 0;
        margin-bottom: 15px;
    }
}


.time-input{
  display:flex;
  justify-content: space-between;
}

popup-message {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup-message p {
  margin: 0;
  font-size: 16px;
  text-align: center;
}
.spinner-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
    width: 100%; 
  }

   #largeLabellabel{
    width:495px;
  }