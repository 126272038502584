.services-container {
  text-align: center;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}


.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.service-item {
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.service-icon {
  
  margin: auto;
  display: block;
  width: 60px; 
  height: 60px; 
  background-color: #007bff; 
  border-radius: 50%;
  margin-bottom: 10px;
}

.service-item p {
  margin-top: 10px;
  font-size: 16px;
}

.book-button {
  display: block;
  width: fit-content;
  padding: 10px 20px;
  margin: 20px auto;
  background-color: #007bff; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

@media (max-width: 768px) {
  .services-grid {
      grid-template-columns: 1fr; 
  }
}
