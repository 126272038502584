.contactUsPage-form {
    width: 50%;
    margin: auto;
  }
  
  .contact-form-section {
    background-color: rgba(255, 255, 255, 0.9); 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px; 
  }
  
  .contact-form-section h2 {
    color: #333;
    margin-bottom: 15px;
  }
  
  .contact-form-section form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form-section input,
  .contact-form-section textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form-section button {
    background-color: #800020; 
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
 .container,.MuiContainer-root {
  margin-top:10px!important;
    display: flex!important;
    justify-content: center;
  }
  .spinner-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
    width: 100%; 
  }
  