.header {
  background-color: #000000; 
  color: #fff; /* Adjusted for better readability */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-info {
  font-size: 0.7rem;
  color: white; 
}

.navigation {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.navigation li, .navigation button {
  margin: 0 10px; 
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.navigation li a, .navigation button { 
  text-decoration: none;
  color: white; 
  padding: 5px 15px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.navigation li a:hover, .navigation button:hover {
  color: #000000; 
  background-color: #f1c40f; 
  border-radius: 5px;
}

.navigation button {
  border-radius: 5px;
  background-color: #f1c40f; 
  color: #000000; 
  font-weight: bold;
}

.navBar {
  display: none; 
}

.logo img {
  max-width: 110px; 
  display: flex;
  justify-content: center;
}
nav {
  background-color: transparent !important;
}

@media screen and (max-width: 768px) {
  .navigation {
    display: none; 
  }
  
  .navBar {
    display: flex; 
    justify-content: flex-start;
    width: 100%; 
    background-color: #000000; 
  }

  .header {
    flex-wrap: wrap; 
  }

  .contact-info {
    order: 3; 
    width: 100%;
    text-align: center; 
    margin-top: 10px;
  }

  .logo {
    order: 2; 
    flex-grow: 1; 
    display: flex;
    justify-content: center; 
  }

  .MuiToolbar-root, .MuiPaper-root {
    background-color: #000000 !important;
    width: fit-content;
  }
  .MuiPaper-root-MuiAppBar-root, .MuiPaper-root {
    background-color: #000000 !important;
    width: fit-content;
  }
}

.MuiTypography-root, .MuiListItemText-primary {
  color: #f1c40f !important;
}

@media screen and (max-width: 768px) {
  .navBar {
    display: block; 
  }

  .MuiAppBar-root, .MuiDrawer-paper {
    background-color: #000000 !important; 
  }

  .MuiButtonBase-root .MuiIconButton-label, .MuiListItemText-root {
    color: #f1c40f !important;
  }
}
@media (orientation: landscape) and (max-width: 844px) {
   
  .navigation{
    list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  }
  .contact-info {
    font-size: 0.5rem;
    color: white; 
  }
  
}
