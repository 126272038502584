.main-page {
    color: #fff; 
    background-color: #000; 
    padding: 20px;
    font-family: Arial, sans-serif; 
    position: relative; 
     display: flex;
    flex-direction: column;
    min-height: 80vh;
    /* height: 100%!important; */
    
  }
  .title-section{
    display: grid;
    justify-content: space-between;
  }
  .content-section {
    /* flex: 1; */
  }
  .paragraph{
    width: 50%;
  }
  .bottom-sections {
    margin-top: 10px;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
  
  }
  
  .choose-us-section {
    background-color: #f1c40f; 
    padding: 20px;
    border-radius: 20px; 
    margin-bottom: 20px; 
    width: 50%;
    display: grid;
    justify-content: center;
  }
  
  .choose-us-section h3, .choose-us-section ul {
    margin: 0; 
  }
  
  .choose-us-section ul {
    list-style-type: none; 
  }
  
  .choose-us-section li {
    padding: 5px 0; 
  }
  
  
  
  .title-section h1, .title-section h2 {
    text-align: center;
    color: #f1c40f;
  }
  
  .text-and-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .circle-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #f1c40f; 
    margin-left: 20px; 
    flex-shrink: 0; 
  }
  
  .circle-image img {
    width: 300px;
    height: auto;
    object-fit: cover;
    margin-top: -1px;
    margin-left: -59px;
  }
  .shaped-image{
    width: 365px;
    height: 258px;
    border-radius: 30%;
    overflow: hidden;
    border: 3px solid #f1c40f; 
    margin-left: 800px;
    margin-top: -165px;
    flex-shrink: 0; 
  }
  .shaped-image img{
    width: 388px;
    height: auto;
    object-fit: cover;
    margin-top: 0px;
    margin-left: 2px;
  }
  /* .content-section p, .content-section h3, .content-section ul {
    text-align: left;
  } */
  
  /* @media (max-width: 768px) {
    .text-and-image {
      flex-direction: column-reverse;
      text-align: center;
    }
  
    .circle-image {
      margin: 0 auto 20px; 
    }
  } */
  
  @media (max-width: 768px) {
    .home-container {
      flex-direction: column;
      align-items: center;
      padding: 20px;
      height: auto; 
      width: 100%; 
    }
  
    .title-section,
    .content-section,
    .bottom-sections,
    .choose-us-section {
      width: 100%; 
    }
  
    .paragraph,
    .choose-us-section {
      width: 100%; 
    }
  
    .text-and-image,
    .bottom-sections {
      flex-direction: column;
      align-items: center;
    }
  
    .circle-image,
    .shaped-image {
      margin: 0 auto; 
      width: 120px; 
      height: 120px;
    }
  
    .shaped-image img {
      width: 100%; 
      height: auto; 
    }
  
    .contact-section,
    .contact-icons .icon,
    .barcode img {
      align-items: center; 
    }
  
    .more-info {
      transform: translateX(0%); 
    }
  }
  
  @media (orientation: landscape) and (max-width: 768px) {
    
    .home-container {
      flex-direction: row; 
      justify-content: center;
      padding: 10px; 
      height: auto; 
    }
  
    .text-and-image,
    .bottom-sections {
      flex-direction: row; 
      justify-content: space-around; 
    }
  
    .paragraph,
    .choose-us-section {
      width: 45%; 
    }
  
    .circle-image,
    .shaped-image {
      margin: 0 10px; 
      width: 100px; 
      height: 100px;
    }
  
    .contact-section {
      flex-direction: row; 
      justify-content: space-between;
      width: 100%; 
    }
  
    .contact-icons {
      flex-direction: row; 
      justify-content: space-evenly; 
    }
  }
  @media (orientation: landscape) and (max-width: 844px) {
  .shaped-image{
    width: 365px;
    height: 258px;
    border-radius: 30%;
    overflow: hidden;
    border: 3px solid #f1c40f;
    margin-left: 433px;
    margin-top: -165px;

  }
}
  
  .floating-contact-section {
    position: absolute;
    right: 20px;
    bottom: 20px;
    text-align: right;
  }
  
  .more-info {
    font-size: 18px;
    color: #000; 
    background-color: #f1c40f; 
    border-radius: 50px 0 0 50px; 
    padding: 10px 43px;
    display: inline-block;
    transform: translateX(50%);
  }
  
  .contact-icons .icon {
    display: block;
    color: #fff;
    margin: 5px 0;
  }
  
  .contact-icons .icon svg {
    color: #f1c40f; 
    margin-right: 10px;
  }
  
  .barcode {
    display: flex;
    justify-content: flex-end;
  }
  
  
  @media (max-width: 768px) {
    .title-section{

    }
    .text-and-image {
      flex-direction: column;
      text-align: center;
    }
  
    .circle-image {
      margin: 0 auto 20px; 
      width: 120px; 
      height: 120px; 
     
    }
  
    .circle-image img {
      width: 300px;
      height: auto;
      object-fit: cover;
      margin-top: -1px;
      margin-left: -59px;
    }
  
    .bottom-sections {
      /* display: grid;  */
      text-align: center; 
      margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }
  .shaped-image{
    margin-top: 10px;
    width: 336px;
    height: 258px;
    border-radius: 30%;
    overflow: hidden;
    border: 3px solid #f1c40f;
    margin-left: 14px;
    /* flex-shrink: 0; */
  }
    
  .paragraph{
    width: auto;
  }
    .choose-us-section {
      width: 100%; 
      margin: 20px 0;
      width: auto;
    }
  
    .choose-us-section ul {
      padding: 0; 
    }
  
    .choose-us-section li {
      text-align: left; 
      padding-left: 20px;
    }
  
    
    .contact-section {
      position: static; 
      transform: none; 
      margin-top: 20px; 
      padding: 10px; 
      width: auto; 
      border-radius: 20px; 
      display: flex; 
      flex-direction: column; 
      align-items: center; 
    }
  
    .contact-icons {
      display: flex; 
      flex-direction: column;
      align-items: center;
      margin-top: 10px; 
    }
  
    .contact-icons .icon {
      margin: 5px 0; 
    }
    .more-info {
      font-size: 18px;
      color: #000; 
      background-color: #f1c40f; 
      border-radius: 50px 0 0 50px; 
      padding: 10px 43px;
      display: inline-block;
      transform: translateX(10%);
    }
  
    .barcode img {
      width: 80%; 
      max-width: 150px; 
      margin-top: 20px; 
    }
  }
 
  @media (orientation: landscape) and (max-width: 933px) {
   
    .shaped-image{
      margin-top: -200px;
      width: 336px;
      height: 258px;
      border-radius: 30%;
      overflow: hidden;
      border: 3px solid #f1c40f;
      margin-left: 420px;
    }
    
  }