body {
  font-family: Arial, sans-serif;
  /* width: fit-content; */
}

nav {
  background-color: #f8f9fa;
  padding: 10px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

form label {
  display: flex;
  flex-direction: column;
}

form button {
  margin-top: 10px;
  padding: 10px;
}

@media (max-width: 768px) {
  body {
    font-family: Arial, sans-serif;
    width: auto!important;
  }
  @media (orientation: landscape) and (max-width: 768px) {
    body {
      font-family: Arial, sans-serif;
      width: auto!important;
    }
  }
  @media (orientation: landscape) and (max-width: 844px) {
    body {
      font-family: Arial, sans-serif;
      width: auto!important;
    }
    nav{
      overflow:none
    }
  }

  @media (orientation: landscape) and (max-width: 933px) {
    body {
      font-family: Arial, sans-serif;
      width: auto!important;
    }
    nav{
      overflow:none
    }
  }
}